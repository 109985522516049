import React, { useEffect } from 'react';

const domains = {
  development: 'https://europe-west3-radio-staging.cloudfunctions.net',
  staging: 'https://europe-west3-radio-staging.cloudfunctions.net',
  production: 'https://europe-west3-radio-c1928.cloudfunctions.net',
};

const endpoint = (name) => `${domains[process.env.NODE_ENV]}/${name}`;

function App() {
  console.log(process.env);

  useEffect(() => {
    async function fetchTimeline() {
      const response = await fetch(endpoint('timeline'));
      const data = await response.json();
      console.log(data);
    }

    fetchTimeline();
  }, []);
  return (
    <>
      <img
        alt="radio logo"
        src="favicon.png"
        width="128"
        className="mx-auto d-block"
      />
      <div className="card rounded-lg shadow-sm p-2 mt-3 container-sm">hi</div>
    </>
  );
}

export default App;
